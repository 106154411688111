import Vue from 'vue';
import store from "./store";
import {i18n} from './plugins/i18n'
import {BASE_URL} from "@/requests";


var Utils = {

    //set the token to localStorage
    setLocalStorage(storageName, data) {
        localStorage.setItem(storageName, JSON.stringify(data));
    },

    //get the token from localStorage
    getLocalStorage(storageName) {
        return JSON.parse(localStorage.getItem(storageName));
    },

    // delete the token from localStorage
    deleteLocalStorage(storageName) {
        localStorage.removeItem(storageName);
    },

    //create the Authorization headers for request
    bearerToken(token) {
        return 'Bearer' + token
    }
};

export default Utils
