import Vue from "vue";
import VueRouter from "vue-router";
import Map from "./components/map/Map";
import App from "./App";


Vue.use(VueRouter);

const routes = [

  {path: '/demo', component: App},
  {path: '/map', component: Map},
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

export default router
