import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginInformation: {},
    vehiclePosition: [],
    loginDialog: false,
    snackBar: false,
    snackBarObj: {},
    loadingModal: false,
    allVehicles: [],

  },
  getters: {
    loginInformation: state => state.loginInformation,
    vehiclePosition: state => state.vehiclePosition,
    loginDialog: state => state.loginDialog,
    snackBar: state => state.snackBar,
    snackBarObj: state => state.snackBarObj,
    loadingModal: state => state.loadingModal,
    allVehicles: state => state.allVehicles,


  },
  mutations: {
    setLoginInformation: function (state, data) {
      state.loginInformation = data
    },
    setVehiclePosition: function (state, data) {
      state.vehiclePosition = data
    },
    setLoginDialog: function (state, data) {
      state.loginDialog = data
    },
    setSnackBar: function (state , snackBarData) {
      state.snackBar = snackBarData
    },
    setSnackBarObj: function (state, snackBarObjData) {
      state.snackBarObj = snackBarObjData
    },
    setLoadingModal: function (state, loadingModalData) {
      state.loadingModal = loadingModalData
    },
    setAllVehicles: function (state, data) {
      state.allVehicles = data
    }

  },
  actions: {},
  modules: {},
});
