import Vue from 'vue';
import VueI18n from 'vue-i18n';


Vue.use(VueI18n);

const messages = {
    'gr': {
        //login Page
        loginButton: 'Σύνδεση',
        emailLabel: 'Email Χρήστη',
        passWordLabel: 'Κωδικός Πρόσβασης',
        signUpButton: 'Εγγραφή',
        newPassWordRegistration : 'Εισαγωγή νέου κωδικού',
        savePassword : 'Αποθήκευση',
        newPassWord: 'Νέος κωδικός',
        newPassWordConfirmation : 'Επιβεβαίωση Νέου κωδικού',
        resetPassword : 'Ανάκτηση κωδικού',
        incorrectPasswords: 'Ο κωδικός δεν επαληθέυεται',
        sendEmail : 'Αποστολή email',
        selectVehicle : 'Επιλογή οχήματος',
        language: 'Γλώσσα',
        emailIsRequired: 'Το email είναι υποχρεωτικό',
        noRegister: 'Δεν καταχωρήθηκε',
        successfulEmail: 'Επιτυχής αποστολή email',
        userAlreadyExist: 'Ο χρήστης υπάρχει ήδη',
        userNotFound: 'Ο χρήστης δεν βρέθηκε',
        invalidPassword: 'Μή έγκυρος κωδικός',
        loginNotSuccess:  'Μη επιτυχής σύνδεση',
        logoutNotSuccess:  'Μη επιτυχής αποσύνδεση',
        logoutSuccess:  'Επιτυχής αποσύνδεση',
        emailNotSent:  'Δεν στάλθηκε',
        invalidNewPassword: 'Μη έγκυρος νέος κωδικός',
        recoveryNotSuccess: 'Ανεπιτυχής ανάκτηση',
        emptyEmailAndPassword: 'Εισαγάγετε στοιχεία σύνδεσης',
        emptyEmail: 'Εισαγάγετε διεύθυνση email',
        emptyPassword:  'Εισαγάγετε κωδικό',
        // passwordValidationMsg: 'Ο κωδικός πρέπει να αποτελείται από τουλάχιστον 1 κεφαλαίο γράμμα, 1 μικρό γράμμα, 1 αριθμό και μέγεθος τουλάχιστον 6 χαρακτήρων.'
        passwordValidationMsg: 'Ο κωδικός πρέπει να αποτελείται από τουλάχιστον 3 χαρακτήρες.'

    },
    'en': {
        //login Page
        loginButton: 'Login',
        emailLabel: 'Email',
        passWordLabel: 'Password',
        signUpButton: 'Sign up',
        newPassWordRegistration : 'New password registration',
        savePassword : 'Save',
        newPassWord: 'New Password',
        newPassWordConfirmation : 'New Password confirmation',
        resetPassword : 'Reset Password',
        incorrectPasswords : 'Invalid password confirmation',
        sendEmail : 'Send email',
        selectVehicle : 'Select vehicle',
        language: 'Language',
        emailIsRequired: 'Email is required',
        noRegister: 'Was not registered',
        successfulEmail: 'Email sent successfully',
        userAlreadyExist: 'The user already exists',
        userNotFound: 'User not found',
        invalidPassword: 'Invalid Password',
        loginNotSuccess:  'Failed login',
        logoutNotSuccess:  'Failed logout',
        logoutSuccess:  'Successful logout',
        emailNotSent:  'Email was not sent',
        invalidNewPassword: 'New password is invalid',
        recoveryNotSuccess: 'Recovery is not success',
        emptyEmailAndPassword: 'Empty credentials',
        emptyEmail: 'Email is empty',
        emptyPassword:  'Password is empty',
        // passwordValidationMsg: 'Password contains at least One Uppercase, One Lowercase, One Number, at least 6 characters'
        passwordValidationMsg: 'Password contains at least 6 characters'
    }
}

export let i18n = new VueI18n(
    {
        locale: 'gr',
    messages
    }
);


