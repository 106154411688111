import route from './router'
import utils from './utilities';
import service from './services'
import store from './store'
import {i18n} from "./plugins/i18n";


let properties = {

    getErrorMessage(response) {

        var message = response.data.message;


        if (response.status == 401) {

            service.cancelGetVehiclesPosition();
            store.commit('setVehiclePosition', []);
            store.commit('setLoginDialog', true);
            store.commit('setSnackBarObj', {text: response.data.message});
            store.commit('setSnackBar', true);
            utils.deleteLocalStorage('accessToken');
            return
        }


        if (message.includes("content.can.not.be.empty")) {
            store.commit('setSnackBarObj', {text: message});
            store.commit('setSnackBar', true)
        } else if ((message.includes("email.is.required"))) {
            store.commit('setSnackBarObj', {text: i18n.t('emailIsRequired')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("no.register"))) {
            store.commit('setSnackBarObj', {text: i18n.t('noRegister')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("email.send.successfully"))) {
            store.commit('setSnackBarObj', {text: i18n.t('successfulEmail')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("user.already.exist"))) {
            store.commit('setSnackBarObj', {text: i18n.t('userAlreadyExist')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("user.not.found"))) {
            store.commit('setSnackBarObj', {text: i18n.t('userNotFound')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("invalid.password"))) {
            store.commit('setSnackBarObj', {text: i18n.t('invalidPassword')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("login.not.success"))) {
            store.commit('setSnackBarObj', {text: i18n.t('loginNotSuccess')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("logout.not.success"))) {
            store.commit('setSnackBarObj', {text: i18n.t('logoutNotSuccess')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("logout.success"))) {
            store.commit('setSnackBarObj', {text: i18n.t('logoutSuccess')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("content.can.not.be.empty"))) {
            store.commit('setSnackBarObj', {text: message});
            store.commit('setSnackBar', true)
        } else if ((message.includes("email.is.required"))) {
            store.commit('setSnackBarObj', {text: i18n.t('emailIsRequired')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("email.is.not.sent"))) {
            store.commit('setSnackBarObj', {text: i18n.t('emailNotSent')});
            store.commit('setSnackBar', true)
        }else if ((message.includes("user.already.exist"))) {
            store.commit('setSnackBarObj', {text: i18n.t('userAlreadyExist')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("new.password.is.invalid"))) {
            store.commit('setSnackBarObj', {text: i18n.t('invalidNewPassword')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("sign.in.is.not.success"))) {
            store.commit('setSnackBarObj', {text: i18n.t('loginNotSuccess')});
            store.commit('setSnackBar', true)
        } else if ((message.includes("recovery.is.not.success"))) {
            store.commit('setSnackBarObj', {text: i18n.t('recoveryNotSuccess')});
            store.commit('setSnackBar', true)
        } else {

            store.commit('setSnackBarObj', {text: message});
            store.commit('setSnackBar', true);
        }
    }
};

export default properties
