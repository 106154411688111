<template>
    <l-map style="height:100%;width:100%;z-index: 1" :zoom="zoom" :center="center" :bounds="bounds" ref="myMap">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :key="i" v-for="(m , i) in markerLatLng" :lat-lng="m" v-if="markerLatLng != null">
            <l-icon :icon-url="markerIcon[i]" :icon-size="[35,45]"/>
        </l-marker>
    </l-map>
</template>

<script>

    import { latLng, icon, latLngBounds } from "leaflet";
    import {LMap, LTileLayer, LMarker, LIcon, LControlLayers} from 'vue2-leaflet';
    import busMoving from '../../assets/map/bus_moving.png'
    import busStopped from '../../assets/map/bus_stopped.png'
    import busStoppedEngineOff from '../../assets/map/bus_stopped_engine_off.png'


    export default {
        components: {
            LMap,LTileLayer,LMarker, LIcon, LControlLayers},
        name: "Map",
        data: () => ({
            markerIcon: null,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 15,
            center: [37.9924453, 23.7449098],
            markerLatLng: null,
            bounds: null,
        }),

        methods: {

            addMarkerToMap(vehicle) {

                this.markerLatLng = [];
                this.markerIcon = [];

                if (vehicle.length > 0) {
                    let bound = []
                    for (var i = 0; i < vehicle.length; i++) {

                        this.markerLatLng.push(latLng(vehicle[i].Phi, vehicle[i].Lamda));
                        this.markerIcon.push(this.iconSelector(vehicle[i]));
                        bound.push([vehicle[i].Phi, vehicle[i].Lamda])
                    }

                    this.bounds = latLngBounds([bound]);

                }

                // if (Object.keys(vehicle).length > 0) {
                //     this.iconSelector(vehicle);
                //     this.center = [vehicle.Phi, vehicle.Lamda];
                //     this.markerLatLng = latLng(vehicle.Phi, vehicle.Lamda)
                //     return
                // }
                // this.center = [37.9924453, 23.7449098];
                // this.markerLatLng = null
            },

            iconSelector(vehicle) {
              if (vehicle.statusColor == "blue") {
                return busMoving;
              } else if (vehicle.statusColor == "red") {
                  return busStoppedEngineOff
              } else {
                  return busStopped;
              }
            }
        },

        computed :{

            vehiclePosition: function () {
                return this.$store.getters.vehiclePosition
            }
        },

        watch: {

            'vehiclePosition': function (val) {

                this.addMarkerToMap(val);
            }
        }
    }
</script>

<style scoped>

</style>