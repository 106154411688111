import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from './plugins/vuetify'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'leaflet/dist/leaflet.css';
import {i18n} from './plugins/i18n';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag);

Vue.use(VueAxios, axios)

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
