import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#941A4C',
                secondary: '#14296d'
            },
            dark: {
                primary: '#941A4C',
                secondary: '#424242'
            }
        },
    },
    dark: true
});
