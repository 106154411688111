import axios from 'axios'
import store from './store'
import utils from './utilities';
import prop from './properties'


// export const BASE_URL = 'https://powerfleet.net';
export const BASE_URL = 'bus-tracker';
// export const BASE_URL = 'POWERFLEET6000';
// export const BASE_URL = 'https://powerfleet.net:8443/POWERFLEET6000';


var request = {


    get(path, getCallBack) {

        axios.get(BASE_URL + path,
            {headers: {
                    'Content-Type': 'application/json'
                }})
            .then((response =>{
                getCallBack(response.data)
            }))
            .catch((error => {
                console.log(error);
                prop.getErrorMessage(error.response);
            }))
    },

    post(path, data,  postCallBack) {

        axios.post(BASE_URL + path,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': utils.getLocalStorage('accessToken')
                }
            })
            .then((response =>{
                postCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response);
            }))
    },

    put(path, data ,  putCallBack) {

        axios.put(BASE_URL + path, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': utils.bearerToken()
                }
            }
        )
            .then((response =>{
                putCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response);
            }))
    },


    signup(data,  postCallBack) {

        axios.post(BASE_URL + '/signup',
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response =>{
                postCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response);
            }))
    },

    signin(data,  postCallBack) {

        axios.post(BASE_URL + '/signin',
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response =>{
                postCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                console.log(error.response);
                prop.getErrorMessage(error.response);
            }))
    },


    getLoginInformations(getCallBack) {

        axios.get(BASE_URL + '/secure/loginInformations',
            {headers: {
                    'x-access-token': utils.getLocalStorage('accessToken')
                }})
            .then((response =>{
                getCallBack(response.data)
            }))
            .catch((error => {
                console.log(error);
                prop.getErrorMessage(error.response);
            }))
    },

    changePassword(data ,  putCallBack) {

        axios.put(BASE_URL + '/secure/change-password', data, {
                headers: {
                    'x-access-token': utils.getLocalStorage('accessToken')
                }
            }
        )
            .then((response =>{
                putCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response);
            }))
    },

    logout(data, logoutCallBack) {

        axios.delete(BASE_URL + '/secure/logout', {
                headers: {
                    'x-access-token': utils.getLocalStorage('accessToken')
                },
            data
            }
        )
            .then((response =>{
                logoutCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response);
            }))
    },

    resetEmail(data,  postCallBack) {

        axios.post(BASE_URL + '/reset-password',
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response =>{
                postCallBack(response.data)
            }))
            .catch((error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response);
            }))
    },


}

export default request
