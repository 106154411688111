<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :is-full-page="fullPage">
            <div class="half-circle-spinner">
                <div class="circle circle-1"></div>
                <div class="circle circle-2"></div>
            </div>
        </loading>
    </div>
</template>

<script>

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "LoadingModal",
        data() {
            return {
                fullPage: true
            }
        },
        components: {
            Loading
        },
        props: {
            isLoading: Boolean
        }
    }
</script>

<style scoped>
    .half-circle-spinner, .half-circle-spinner * {
        box-sizing: border-box;
    }

    .half-circle-spinner {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        position: relative;
    }

    .half-circle-spinner .circle {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: calc(60px / 10) solid transparent;
    }

    .half-circle-spinner .circle.circle-1 {
        border-top-color: #061E42;
        animation: half-circle-spinner-animation 1s infinite;
    }

    .half-circle-spinner .circle.circle-2 {
        border-bottom-color: #061E42;
        animation: half-circle-spinner-animation 1s infinite alternate;
    }

    @keyframes half-circle-spinner-animation {
        0% {
            transform: rotate(0deg);

        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
