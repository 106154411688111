
import Vue from 'vue';
import store from './store'
import request from './requests'

export var requestService = null;

var Serv = {

    getVehiclesPosition(vehicles) {

        let path = '/vehiclesCurrentPosition'


        if  (requestService == null)
            requestService = setInterval(() => {
                request.post(path ,vehicles,  this.vehiclesCurrentPositionCallBuckSuccess);
            }, 10000);
    },

    cancelGetVehiclesPosition() {
        clearInterval(requestService);
        requestService = null;
    },

    vehiclesCurrentPositionCallBuckSuccess(response) {

        if (response.length > 0) {
            store.commit('setVehiclePosition', response)
            return
        }

        store.commit('setVehiclePosition', [])

    }
};

export default Serv