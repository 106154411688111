<template>
    <v-dialog v-model="$store.getters.loginDialog" max-width="500" persistent>
        <v-card>
<!--            <v-tabs v-model="tab" hide-slider>-->
<!--                <v-tab>-->
<!--                   {{ i18n.t('loginButton') }}-->
<!--                </v-tab>-->
<!--                <v-tab>-->
<!--                    {{ i18n.t('signUpButton') }}-->
<!--                </v-tab>-->
<!--            </v-tabs>-->

            <v-card-title>
                {{ tab === 0 ? i18n.t('loginButton') : tab === 1 ? i18n.t('signUpButton') : tab === 3 ? i18n.t('resetPassword') : i18n.t('newPassWordRegistration')  }}
                <v-spacer></v-spacer>
                <v-icon v-if="tab === 2 && fromChangePassword" color="primary" @click="closeDialog">close</v-icon>
            </v-card-title>

            <v-card-text>
                <v-form v-if="tab === 0" >
                    <v-text-field
                            :label="i18n.t('emailLabel')"
                            v-model="email"
                            type="email"
                            required
                    ></v-text-field>
                    <v-text-field
                            :label="i18n.t('passWordLabel')"
                            v-model="password"
                            :type="showPassWord ? 'text' : 'password'"
                            :append-icon="showPassWord ? 'visibility' : 'visibility_off'"
                            @click:append="showPassWord = !showPassWord"
                            required
                    ></v-text-field>
                </v-form>
                <v-form v-else-if="tab === 1">
                    <v-text-field
                            :label="i18n.t('emailLabel')"
                            v-model="email"
                            type="email"
                            required
                    ></v-text-field>
                </v-form>
                <v-form v-else-if="tab === 2">
                    <v-text-field
                            :label="i18n.t('newPassWord')"
                            v-model="newPassword"
                            :rules="[rules.required, rules.passWordValidation]"
                            :type="showPassWord ? 'text' : 'password'"
                            :append-icon="showPassWord ? 'visibility' : 'visibility_off'"
                            @click:append="showPassWord = !showPassWord"
                            required
                    ></v-text-field>
                    <v-text-field
                            :label="i18n.t('newPassWordConfirmation')"
                            v-model="newPassword2"
                            type="password"
                            required
                    ></v-text-field>
                </v-form>

                <v-form v-else-if="tab === 3">
                    <v-text-field
                            :label="i18n.t('emailLabel')"
                            v-model="email"
                            type="email"
                            required
                    ></v-text-field>
                </v-form>
            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="primary"   @click="submit">
                    {{ tab === 0 ? i18n.t('loginButton') : tab === 1 ? i18n.t('signUpButton') : tab === 3 ?  i18n.t('sendEmail') :  i18n.t('savePassword') }}
                </v-btn>

                <v-btn v-if="tab !== 2" color="secondary"  @click="changeOption(tab)">
                    {{ tab === 0 ? i18n.t('signUpButton') : i18n.t('loginButton') }}
                </v-btn>

            </v-card-actions>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn v-if="tab === 0" color="secondary" text small @click="resetPassword(tab)">
                    {{ i18n.t('resetPassword')  }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {i18n} from "@/plugins/i18n";
    import request from "@/requests";
    import utils from "@/utilities";

    export default {
        name: "LoginSignup",
        data() {
            return {
                i18n,
                utils,
                dialog: false,
                tab: 0,
                loginForm: {
                    email: '',
                    password: ''
                },
                email: '',
                password: '',
                newPassword: '',
                newPassword2: '',
                signupForm: {
                    email: ''
                },
                fromChangePassword : false,
                showPassWord: false,
                rules : {
                    required: value => !!value || i18n.t('emptyPassword'),
                    // passWordValidation: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^\s]{6,}$/.test(value) || i18n.t('passwordValidationMsg'),
                    passWordValidation: v =>  v.length >= 3 || i18n.t('passwordValidationMsg'),
                    // min: v => v ? v.length >= 6 || 'Min ' + 6 +' characters' : '',
                    emailMatch: () => ('The email and password you entered don\'t match'),
                }
            }
        },
        methods: {

            changeOption(tab){
                if(tab === 0){
                    this.tab = 1;
                }else{
                    this.tab = 0;
                }
            },

            resetPassword(){
                this.tab = 3;
            },


            loginValidation() {
                if (this.email.replace(/\s/g,'') == '' && this.password.replace(/\s/g,'') == ''){
                    this.$store.commit('setSnackBarObj', {text: i18n.t('emptyEmailAndPassword')});
                    this.$store.commit('setSnackBar', true);
                    return false;
                }
                else if (this.email.replace(/\s/g,'') == '') {
                    this.$store.commit('setSnackBarObj', {text: i18n.t('emptyEmail')});
                    this.$store.commit('setSnackBar', true);
                    return false;
                }
                else if (this.password.replace(/\s/g,'') == '') {
                    this.$store.commit('setSnackBarObj', {text: i18n.t('emptyPassword')});
                    this.$store.commit('setSnackBar', true);
                    return false;
                }
                else {
                    return true;
                }
            },

            newPasswordValidation(){
                if (this.newPassword.replace(/\s/g,'') == '' || this.newPassword2.replace(/\s/g,'') == '' ) {
                    this.$store.commit('setSnackBarObj', {text: i18n.t('emptyPassword')});
                    this.$store.commit('setSnackBar', true);
                    return false;
                }
                else if (this.newPassword !== this.newPassword2){
                    this.$store.commit('setSnackBarObj', {text: i18n.t('incorrectPasswords')});
                    this.$store.commit('setSnackBar', true);
                    return false;
                }else{
                    return true;
                }
            },


            submit() {

                this.$store.commit('setLoadingModal', true);
                if (this.tab === 0) {
                    // Handle login
                    if (this.loginValidation()) {

                        request.signin({email: this.email, password: this.password}, this.signinCallback)
                    }


                } else if(this.tab ===1) {
                    // Handle signup


                    request.signup({  email: this.email}, this.signupCallback);
                } else if (this.tab === 2){
                    // Handle change password
                    if (this.newPasswordValidation()) {

                        let data = {};
                        data.newPassword = this.newPassword.trim();

                        request.changePassword(data, this.changePasswordCallback)

                    }
                } else if (this.tab === 3){
                    // Handle reset password

                    let data = {};
                    data.email = this.email.trim();

                    request.resetEmail(data, this.resetEmailCallback)

                }
            },

            resetEmailCallback(response){

                this.$store.commit('setLoadingModal', false);
                this.$store.commit('setSnackBarObj', {text: i18n.t('successfulEmail')});
                this.$store.commit('setSnackBar', true);
                this.tab = 0;

            },

            changePasswordCallback(response){

                this.$store.commit('setLoadingModal', false);
                if(Object.keys(response).length > 0){
                    utils.setLocalStorage("loginObject", response);
                    this.$store.commit('setLoginInformation', response);

                    if(response.status === "newUser"){
                        this.tab = 2;
                        return;
                    }else{
                        this.$store.commit('setLoginDialog', false);
                    }

                }
            },


            signupCallback(response){

                this.$store.commit('setLoadingModal', false);
                if(response.message == "email.send.successfully"){
                    this.$store.commit('setSnackBarObj', {text: i18n.t('successfulEmail')});
                    this.$store.commit('setSnackBar', true);
                    this.tab = 0;
                }
            },


            signinCallback(response){
                console.log(JSON.stringify(response));

                this.$store.commit('setLoadingModal', false);
                if(response.accessToken){
                    utils.setLocalStorage("accessToken", response.accessToken);
                    utils.setLocalStorage("loginObject", response);
                    this.$store.commit('setLoginInformation', response);

                    if(response.status === "newUser"){
                        this.tab = 2;
                    }else{
                        this.$store.commit('setLoginDialog', false);
                    }

                    let path = '/getAllVehicles';
                    request.post(path, {email: response.email}, this.getAllVehiclesCallBack)
                }
            },

            getAllVehiclesCallBack(response) {

                this.$store.commit('setAllVehicles', response);
            },

            closeDialog(){
                this.$store.commit('setLoginDialog', false);
            }
        }
    }
</script>
