<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app>
    <v-app-bar
            app
      color="primary"
      dark>
      <div class="d-flex align-center">
        <v-img src="./assets/LOGO_POWERFLEET.png" max-height="75" max-width="100" alt="">

        </v-img>
      </div>

      <v-spacer></v-spacer>

      <v-col  xl="2" lg="2" md="4" sm="6" class="mt-2 vehicles" cols="6">
        <v-select
                v-model="select"
                :items="$store.getters.allVehicles"
                item-text="plate"
                :label="i18n.t('selectVehicle')"
                persistent-hint
                return-object
                @change="selectPlate"
                single-line>
        </v-select>
      </v-col>

        <v-menu bottom
                offset-y
                origin="center center"
                transition="scale-transition">

          <template v-slot:activator="{ on }">

            <v-btn
                    icon
                    v-on="on">
              <v-icon>account_circle</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                    :key="i"
                    @click="logout(item)"
                    v-for="(item, i) in dropDownListItem">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      <v-menu :close-on-content-click="false"
              :nudge-width="100"
              :offset-y="offset" origin="right top"
              bottom
              transition="scale-transition"
              v-model="menuLang"
      >
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn bottom
                   class=""

                   small
                   icon
                   v-on="on">
              <v-icon>language</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>

        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center font-weight-bold">{{i18n.t('language')}}
                <v-icon color="primary" small>flag</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-divider></v-divider>
                <v-row>
                  <v-tooltip bottom :key="language.id" v-for="language in langs">
                    <template v-slot:activator="{ on }">
                      <v-col @click="setCountryFlag(language.abr)" class="pr-0" v-on="on" :style="{cursor: 'pointer'}">
                        <country-flag :country="language.image" size='normal'/>
                      </v-col>
                    </template>
                    <span>{{language.title}}</span>
                  </v-tooltip>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-menu>

    </v-app-bar>

    <v-main>
      <Map/>
<!--      <v-dialog-->
<!--              v-model="dialog"-->
<!--              persistent-->
<!--              max-width="290">-->
<!--        <v-card>-->
<!--          <v-card-title class="text-h5">-->
<!--            Login-->
<!--          </v-card-title>-->
<!--          <v-card-text>-->
<!--            <v-text-field v-model="password" type="password"-->
<!--                          label="PassWord"-->
<!--                          @keyup.enter="login"-->
<!--                          required-->
<!--            ></v-text-field>-->
<!--            <span class="caption red&#45;&#45;text" v-if="error">Password is Wrong. Try again</span>-->
<!--          </v-card-text>-->
<!--          <v-card-actions>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn-->
<!--                    color="green darken-1"-->
<!--                    text-->
<!--                    @click="login">-->
<!--              Login-->
<!--            </v-btn>-->
<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
<!--      -->

      <LoginSignup ref="loginSignupDialogRef"/>
    </v-main>


    <ErrorSnackBar v-if="$store.getters.snackBar"/>

    <LoadingModal :isLoading="$store.getters.loadingModal"/>



  </v-app>
</template>

<script>

  import request from './requests'
  import utils from './utilities'
  import service from './services'
  import Map from "./components/map/Map";
  import LoginSignup from "@/components/LoginSignup";
  import ErrorSnackBar from "@/components/ErrorSnackBar";
  import LoadingModal from "@/components/LoadingModal";

  import CountryFlag from 'vue-country-flag'
  import {i18n} from "@/plugins/i18n";

export default {

  name: 'App',
  components: {LoginSignup, Map, ErrorSnackBar, LoadingModal, CountryFlag},
  data: () => ({
    i18n,
    select: {},
    items: [],
    dialog: true,
    password: '',
    error: false,
    offset: true,
    menuLang: false,
    dropDownListItem :[
      {
        id: 1, title: 'Logout',
      },
      {
        id: 2, title: 'Change Password'
      }
      ],
    langs: [
      {
        id: 1, title: 'Greek', abr: 'gr', image : 'gr'
      },
      {
        id: 2, title: 'English', abr: 'en', image: 'gb'
      } ],
    selectedVehicles: [],
  }),

  methods: {


    login() {

      if (this.password == '6000') {

        this.error = false;
        this.dialog = false;
        this.password = '';
        return
      }

      this.password = '';
      this.error = true
    },

    getAllVehicles(email) {
      let path = '/getAllVehicles';  request.post(path, {email: email}, this.getAllVehiclesCallBack)
    },

    getAllVehiclesCallBack(response) {

      this.$store.commit('setAllVehicles', response);
    },

    selectPlate() {

      let data = [];
      let obj = {customerId : this.select.customerId , vehicleId: this.select.vehicleId}

      data = [obj];
      this.selectedVehicles = data;

      let path = '/vehiclesCurrentPosition';
      request.post(path, data, this.getVehiclesPositionCallBack)

    },

    getVehiclesPositionCallBack(response) {


      console.log(response);
      if (response.length > 0) {
        this.$store.commit('setVehiclePosition', response);
      }
      else {
        this.$store.commit('setVehiclePosition', []);
      }
      service.cancelGetVehiclesPosition();
      service.getVehiclesPosition(this.selectedVehicles);
    },

    showLoginOrSignupDialog(){

      let token = utils.getLocalStorage("accessToken");

      if (token) {
        this.$store.commit('setLoginDialog', true);
        request.getLoginInformations(this.getLoginInformationsCallback);
        return
      }


      this.$store.commit('setLoginDialog', true);
    },

    getLoginInformationsCallback(response){

      if(Object.keys(response).length > 0){

        utils.setLocalStorage("loginObject", response);
        this.$store.commit('setLoginInformation', response);

        if(response.status === 'newUser'){

          this.$refs.loginSignupDialogRef.tab = 2;
          this.$store.commit('setLoginDialog', true);
          return
        }

        let loginObject = utils.getLocalStorage("loginObject");

        this.getAllVehicles(loginObject.email);
        this.$store.commit('setLoginDialog', false);
      }

    },

    logout(item) {
      // logout selection
      if (item.id == 1) {

        service.cancelGetVehiclesPosition();
        this.$refs.loginSignupDialogRef.tab = 0;
        this.$refs.loginSignupDialogRef.password = '';
        this.$store.commit('setLoadingModal', true);
        let loginInformation = this.$store.getters.loginInformation;
        request.logout({id: loginInformation.id} ,this.logoutCallBack);
      }
      // reset password selection
      else if (item.id == 2) {

        this.$refs.loginSignupDialogRef.tab = 2;
        this.$refs.loginSignupDialogRef.newPassword = '';
        this.$refs.loginSignupDialogRef.newPassword2 = '';
        this.$refs.loginSignupDialogRef.fromChangePassword = true;
        this.$store.commit('setLoginDialog', true);
      }
    },

    logoutCallBack(response) {

      utils.deleteLocalStorage('accessToken');
      utils.deleteLocalStorage('loginObject');
      this.$store.commit('setLoadingModal', false);
      this.$store.commit('setLoginDialog', true);

    },

    setCountryFlag(flag){
      this.menuLang = false;
      this.$store.commit('setLoadingModal',true);
      i18n.locale = flag;
      console.log(i18n.locale);
      utils.setLocalStorage("language", flag)
      this.$store.commit('setLoadingModal',false);
    }

  },

  created() {
    this.showLoginOrSignupDialog();
    window.scrollTo(0, 0);
    // this.$router.push({path: '/map'});

    if(utils.getLocalStorage("language")){
      i18n.locale = utils.getLocalStorage("language");
    }

    // this.getAllVehicles();
  }
};
</script>

<style scoped>

  .vehicles {
    z-index: 10 !important;
  }

</style>
